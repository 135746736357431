@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Montserrat";
}
html {
  scroll-behavior: smooth;
}

@media (min-width: 640px) {
  .menuOptions {
    display: none;
  }
}
@media (max-width: 640px) {
  .anchor-links a {
    display: none;
  }
}

.fade {
  animation-name: fadeUp;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
}

@keyframes fadeUp {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
